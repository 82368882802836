.time-bg {
  position: relative;
  max-width: 100%;
  height: 350px;
  background: url(../Img/Time/Time-bg.jpg) no-repeat center;
  background-size: cover;
  display: flex;
  flex-direction: column;

}

.time-bg__header {
  margin-left: 30px;
  margin-top: 25px;
  margin-bottom: 97px;
}

.time-bg__body {
  height: 100%;
  margin: auto;
}

.time-title {
  margin: auto;
  font-family: "SFUIText-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  color: #ffffff;
  z-index: 20;
}

.time-svg {
  width: 18px;
}

.time-content {
  background: linear-gradient(180deg, #003285 0%, rgba(0, 77, 202, 0.76) 100%);
  position: relative;
}

.time-study {
  display: flex;
  justify-content: center;
}

.time-study__image {
  max-width: 700px;
  width: 100%;
  margin: auto;
  margin-top: -75px;
  box-shadow: 0px 8px 19px rgba(0, 0, 0, 0.4);
}

.time-content-subtitle {
  margin-top: 100px;
  font-family: 'SFUIDisplay-Regular' sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
}

.time-subcontent-wrap {
  max-width: 1000px;
  margin: auto;
  margin-top: 82px;
  margin-bottom: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 100px;
}

.time-subcontent-item {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 40px;
}

.time-subcontent-text {
  max-width: 162px;
  font-family: "SFUIDisplay-Semibold" sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;

  color: #ffffff;
}

.time-button {
  margin: auto;
  max-width: 200px;
  width: 100%;
  padding: 16px 24px;
  font-family: "SFUIText-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #0a3e94;
  background-color: #fff;
}

.time-space {
  height: 150px;
}

@media (max-width: 992px) {
  .time-subcontent-wrap {
    max-width: 600px;
    width: 100%;
    flex-wrap: wrap;
  }

  .time-content-subtitle {
    margin-top: 70px;
    font-size: 20px;
  }
}

@media (max-device-width: 480px) {
  .time-title {
    font-size: 14px;
  }

  .time-subcontent-wrap {
    max-width: 300px;
    gap: 50px;
  }

  .time-content-subtitle {
    margin-top: 50px;
    font-size: 14px;
  }

  .time-subcontent-image {
    width: 60%;
  }

  .time-button {
    font-size: 13px;
    max-width: 187px;
  }
  .time-svg{
    width: 14px;
  }
  .time-bg__header{
    margin-left: 20px;
    }
}