
.price-bg {
  position: relative;
  max-width: 100%;
  height: 350px;
  background: url(../Img/Price/Price-bg.jpg) no-repeat center;
  background-size: cover;
  display: flex;
  flex-direction: column;

}
.price-bg__header{
  margin-left: 30px;
  margin-top: 25px;
  margin-bottom: 97px;
}
.price-bg__body{
  height: 100%;
  margin: auto; 
}
.price-title {
  margin: auto;
  font-family: "SFUIText-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  color: #ffffff;
  z-index: 20;
}
.price-svg {
  width: 18px;
}
.price-content {
  background: linear-gradient(180deg, #003285 0%, rgba(0, 77, 202, 0.76) 100%);
  position: relative;
}
.price-study{
  
  display: flex;
  justify-content: center;
}
.price-study__image{
  max-width: 700px;
  width: 100%;
  margin: auto;
  margin-top: -75px;
  box-shadow: 0px 8px 19px rgba(0, 0, 0, 0.4);
}
.price-content-subtitle{
  margin-top: 100px;
  font-family: 'SFUIDisplay-Regular' sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 22px;
    line-height: 26px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
}
.prices-subcontent-wrap {
  max-width: 1000px;
  margin: auto;
  margin-top: 82px;
  margin-bottom: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 100px;
}

.prices-subcontent-item {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 40px;
}

.prices-subcontent-text {
  max-width: 162px;
  font-family: "SFUIDisplay-Semibold" sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;

  color: #ffffff;
}

.prices-button {
  margin: auto;
  max-width: 200px;
  width: 100%;
  padding: 16px 24px;
  font-family: "SFUIText-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #0a3e94;
  background-color: #fff;
}
.prices-space{
  height: 150px;
}

@media (max-width: 992px) {
  .prices-subcontent-wrap{
    max-width: 600px;
    width: 100%;
    flex-wrap: wrap;
  }
  .price-content-subtitle{
    margin-top: 70px;
    font-size: 20px;
  }
}
@media (max-device-width: 480px) {
  .price-title{
    font-size: 16px;
  }
  .prices-subcontent-wrap {
    max-width: 300px;
    gap: 50px;
    }
  .price-content-subtitle {
      margin-top: 50px;
      font-size: 16px;
    }
  .prices-subcontent-image{
      width: 60%;
    }
  .prices-button {
    font-size: 13px;
    max-width: 187px;
  }
}